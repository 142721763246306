<template>
  <echarts-com v-loading="loading" id="integratedManagement-processingRate" :options="options"/>
</template>

<script>

import {formatRatio} from "@/utils/util"

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      options: {},
      loading: false
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions(params = {}) {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/after/sales", params);
        if (res.code === 0) {
          let data = [{
            value:  res.data.processRate,
            percentage: formatRatio(res.data.processRate) + "%",
            name: "处理率"
          }]
          this.$set(this, "options", {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'pie',
                radius: ['80%', '90%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  position: 'center',
                  fontWeight: 'bold',
                  color:"white",
                  formatter: (params) => {
                    return params.data.percentage
                  }
                },
                data
              }
            ]
          })
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("运维处理率获取失败");
        this.loading = false;
      }
    }
  }
}
</script>
